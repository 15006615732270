export function getCacheExpiryDuration() {
    return 24 * 60 * 60;
}

export function getPollingDelay() {
    return 5 * 60 * 1000;
}

export function getAttemptsOnEmptyResults() {
    return 5;
}

export function getPageInterval() {
    return 7 * 24 * 60 * 60;
}

export function getRefreshInterval() {
    return 1 * 24 * 60 * 60
}

export function getMaxRetriesOnErrorConfig() {
    return 1;
}