import { I18n } from 'i18n-js';
import { Platform, NativeModules } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import en from '../loc/en.json';
import hi from '../loc/hi.json';

let i18n = new I18n({ en, hi });
i18n.defaultLocale = 'en';
i18n.fallbacks = true;

const setLocaleInStorage = async (locale) => {
  try {
    await AsyncStorage.setItem('locale', locale);
  } catch (error) {
    console.error('Failed to save locale:', error);
  }
};

const getLocaleFromStorage = async () => {
  try {
    const locale = await AsyncStorage.getItem('locale');
    return locale || 'en'; 
  } catch (error) {
    console.error('Failed to load locale:', error);
    return 'en';
  }
};

export const setInitialLocale = async () => {
  let locale = await getLocaleFromStorage();

  if (!locale) {
    if (Platform.OS === 'ios') {
      const appleLocale = NativeModules.SettingsManager?.settings?.AppleLocale;
      locale = appleLocale ? appleLocale.split('_')[0] : 'en';
    } else if (Platform.OS === 'android') {
      const androidLocale = NativeModules.I18nManager?.localeIdentifier;
      locale = androidLocale ? androidLocale.split('_')[0] : 'en';
    }

    locale = locale || 'en';
    await setLocaleInStorage(locale); 
  }

  i18n.locale = locale;
  console.log('Initial locale set to:', locale);
  return locale;
};

export const getText = (key, params = {}) => i18n.t(key, params);

export const setLocale = async (newLocale) => {
  i18n.locale = newLocale;
  await setLocaleInStorage(newLocale);
  console.log('Locale updated to:', newLocale);
};
