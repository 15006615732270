import { extendTheme } from "native-base";

export const nativeBaseTheme = (colorMode: string = 'light') => extendTheme({
    colors: {
        // Add new color
        primary: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#000',
            700: '#404040',
            800: '#262626',
            900: '#171717',
        },
    },
    config: {
        // Changing initialColorMode to 'dark'
        initialColorMode: colorMode,
    },
    components: {
        Box: {
            variants: {
                main: () => {
                    return {
                        bg: colorMode === 'light' ? 'primary.50' : 'primary.900',
                    };
                }
            }
        },
        Button: {
            defaultProps: {
                
            },
        },
        Select: {
            baseStyle: {
              fontSize: 14
            },
        }
    }
});