import Geolocation from '@react-native-community/geolocation';

export const getCurrentLocation = () => {
    return new Promise((resolve) => {
        Geolocation.getCurrentPosition(
            (info) => {
                const location = `${info.coords.latitude},${info.coords.longitude}`;
                resolve(location);
            },
            (error) => {
                console.log('Error occurred while fetching location', error);
                resolve(""); // Resolve with empty string on error
            }
        );
    });
};