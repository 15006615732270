import React from 'react';
import ChooseLoginTypePage from '../../pages/ChooseLoginTypePage';
import ProductReleasePage from '../../pages/ProductReleasePage';
import { NativeBaseProvider } from "native-base";
import { ToastProvider } from 'react-native-toast-notifications'
import { NavigationContainer } from '@react-navigation/native';
import CustomNavigationBar from '../CustomNavigationBar'
import { nativeBaseTheme } from '../../theme'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ResultsPage from 'src/pages/ResultsPage';

import { Platform } from 'react-native';

import ChangePasswordPage from '../../pages/ChangePasswordPage';
import SettingsPage from 'src/pages/SettingsPage';
import UserGuidePage from 'src/pages/UserGuidePage';
import { useSelector } from 'react-redux';

import { getPlatform } from 'src/utils/miscUtils';
import MlDashboardPage from 'src/pages/MlDashboardPage';

const Stack = createNativeStackNavigator();

const linkingConfig = {
    config: {
        screens: {
            MlDashBoardPage: '/',
            ResultsPage: '/results',
            ChooseLoginTypePage: '/switch',
            ChangePasswordPage: '/change-password',
            ProductReleasePage: '/product-release',
            SettingsPage: '/settings',
            UserGuidePage: '/user-guide',
        },
    },
}

const MlStack = () => {

    const colorMode = useSelector((store) => store.app.colorMode);
    const theme = nativeBaseTheme(colorMode);

    return <ToastProvider>
        <NativeBaseProvider key={colorMode} theme={theme}>
            <React.Fragment>
                {
                    Platform.OS === "web" ? <style type="text/css">{`
                    @font-face {
                      font-family: 'MaterialCommunityIcons';
                      src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
                    }
                  `}</style> : null
                }
                <NavigationContainer linking={getPlatform() === "web" ? linkingConfig : null}>
                    <Stack.Navigator screenOptions={{
                        header: (props) => <CustomNavigationBar {...props} />,
                    }}>
                        <Stack.Screen name="ChooseLoginTypePage" component={ChooseLoginTypePage} options={{ showTitle: true, title: 'Change Role', settings: false }} />
                        <Stack.Screen name="MlDashBoardPage" component={MlDashboardPage} options={{ showTitle: false, title: 'Ml', settings: true, orgLogo: true }} />
                        <Stack.Screen name="ResultsPage" component={ResultsPage} options={{ showTitle: true, title: 'Results', settings: false, previous: true }} />
                        <Stack.Screen name="ChangePasswordPage" component={ChangePasswordPage} options={{ previous: true, showTitle: true, title: 'Change password', settings: false }} />
                        {
                            getPlatform() === "web" ?
                                <Stack.Screen name="ProductReleasePage" component={ProductReleasePage} options={{ headerShown: false, title: "Product releases" }} />
                                : null
                        }
                        <Stack.Screen name="Settings" component={SettingsPage} options={{ showTitle: true, headerShown: true, title: "Settings", previous: true }} />
                        <Stack.Screen name="UserGuidePage" component={UserGuidePage} options={{ showTitle: true, headerShown: true, title: "User Guide", previous: true }} />
                    </Stack.Navigator>
                </NavigationContainer>
            </React.Fragment>
        </NativeBaseProvider>
    </ToastProvider>
}

export {
    MlStack
}