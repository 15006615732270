import { StyleSheet } from 'react-native';

export const imageStyle = StyleSheet.create({
    inweonLogo: {
        height: 80,
        width: 240,
        alignItems: 'center',
        marginTop: 12,
        marginBottom: 12,
        resizeMode: "contain"
    },
    inweonLogoForFilters: {
        height: 72,
        width: 192,
        alignItems: 'center',
        marginTop: 4,
        marginBottom: 12,
        resizeMode: "contain"
    },
    indiGrainImage: {
        width: 40,
        height: 40,
        margin: 4,
        alignSelf: 'flex-start'
    },
    dsIndiGrainImage: {
        width: "90%",
        height: 40,
        margin: 4,
        alignSelf: 'flex-start'
    },
    zoomedIndiGrainImage: {
        width: 250,
        padding: 12,
        alignContent: 'center',
        justifyContent: 'center',
        height: 250
    },
    logoStyle: {
        justifyContent: 'center',
        height: 60,
        width: 100,
        resizeMode: 'center',
    },
    imageContainer: {
        width: 150,
        height: 150,
        justifyContent: 'center',
        alignItems: 'center',
      },
      image: {
        width: 150,
        height: 150,
        position: 'absolute',
      },

})